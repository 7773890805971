<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="detailTest-container common-padding"
  >
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <div class="detailTest-detail">
      <img
        v-if="shopDetail.shopLogo"
        class="detail-img"
        :src="shopDetail.shopLogo"
        alt="icon"
      />
      <span class="user-icon" v-else>
        <i class="el-icon-user iconPeople"></i>
      </span>
      <div>
        <div class="detail-title">
          店铺名称: {{ shopDetail.shopName || "--" }}
        </div>
        <span class="detail-item"
          >所属公司: {{ shopDetail.companyName || "--" }}</span
        >
        <span class="detail-item"
          >所属平台: {{ shopDetail.platformName || "--" }}</span
        >
        <span class="detail-item"
          >所属类目: {{ shopDetail.categoryName || "--" }}</span
        >
        <span class="detail-item"
          >所属客户: {{ shopDetail.customerName || "--" }}</span
        >
      </div>
    </div>
    <!-- 查询框区域 -->
    <div class="common-screen-container">
      <div v-show="activeName == '2'" class="common-input-container">
        <span>客服账号:</span>
        <el-select
          class="common-screen-input"
          v-model="params.adminId"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in custList"
            :key="item.id"
            :label="item.nickName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <common-date
              v-if="activeName == '1'"
              :radio="emradioVal"
              :startDate="emParams.checkDate"
              :endDate="emParams.checkEndDate"
              @commonGetDate="commonGetDate2"
              ref="commonReset"
            ></common-date>
            <common-date
              v-if="activeName == '2'"
              :radio="radioVal"
              :startDate="params.checkDate"
              :endDate="params.checkEndDate"
              @commonGetDate="commonGetDate"
              ref="commonReset"
            ></common-date>
      </div>
      <br />
      <div v-show="activeName == '2'" class="common-input-container">
        <span>顾客名称:</span>
        <el-input
          v-model="params.customerNickKeyWord"
          placeholder="请输入内容"
          clearable
          class="common-screen-input"
        ></el-input>
      </div>
      <div v-show="activeName == '2'" class="common-input-container">
        <span>聊天内容:</span>
        <el-input
          v-model="params.msgContentKeyWord"
          placeholder="请输入内容"
          clearable
          class="common-screen-input"
        ></el-input>
      </div>
      <el-button
        v-show="activeName == '2'"
        class="common-screen-btn"
        type="primary"
        @click="() => select()"
        >查 询</el-button
      >
      <el-button v-show="activeName == '2'" class="common-screen-btn" @click="() => clear()" plain
        >重 置</el-button
      >
    </div>

    <!-- 切换 -->
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="情绪检测" name="1">
        <!-- 列表框 -->
        <el-row class="transfer">
          <el-col :span="24">
            <el-row class="transfer-left">
              <!-- 左侧 -->
              <el-col :span="12" class="left">
                <!-- 左侧 -->
                <div class="chat">
                  <div>情绪检测列表</div>
                  <div>
                    <span>负面概率:</span>
                    <el-select
                      v-model="emParams.prob"
                      placeholder="请选择"
                      class="common-screen-input emg"
                      @change="
                        () => {
                          getbadEmotionList();
                        }
                      "
                    >
                      <el-option
                        v-for="item in emOptions"
                        :key="item.prob"
                        :label="item.prob"
                        :value="item.val"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="title-body" style="overflow: overlay;">
                  <div class="count">
                    <div class="first-title">
                      当前情绪检测条数：{{ emTotal || 0 }}条
                    </div>
                  </div>
                  <!-- 列表 -->
                  <ul class="infinite-list">
                    <li
                      v-for="(item, i) in emList"
                      :key="i"
                      :class="{ active: i == isActive2 }"
                      class="infinite-list-item2"
                      @click="
                        () => {
                          isActive2 = i;
                          emchangeFlag(i, item.customerNick, item.subId);
                          emRead(item.id);
                        }
                      "
                    >
                      <div class="itemEM">
                        <img
                          v-show="item.label == '抱怨'"
                          src="@/assets/images/grumble.svg"
                          class="icon"
                          alt=""
                        />
                        <img
                          v-show="item.label == '愤怒'"
                          src="@/assets/images/angry.svg"
                          class="icon"
                          alt=""
                        />
                        <span class="itemlabel">【{{ item.label }}】</span>
                      </div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.customerNick"
                        placement="bottom"
                        :enterable="false"
                        :open-delay="500"
                      >
                        <div class="em-nick">
                          {{ item.customerNick }}
                        </div>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.content"
                        placement="bottom"
                        :enterable="false"
                        :open-delay="500"
                      >
                      <div class="emcontent">{{ item.content }}</div>
                      </el-tooltip>
                      <div
                        :class="{
                          'em-read': true,
                          'em-check-read': i == isActive2
                        }"
                        v-if="item.isRead"
                      >
                        已读
                      </div>
                      <div
                        :class="{
                          'em-no-read': true,
                          'em-check-read': i == isActive2
                        }"
                        v-else
                      >
                        未读
                      </div>
                      <div class="empeople">
                        {{ item.createTime }}
                      </div>
                    </li>
                  </ul>
                  <div class="pagenum">
                    <el-pagination
                      small
                      v-show="emList.length"
                      @size-change="(e) => pageFun2(e, 'pageSize')"
                      @current-change="(e) => pageFun2(e)"
                      style="text-align: center"
                      :page-size="emParams.pageSize"
                      :current-page="emParams.pageNum"
                      layout="prev, pager, next, jumper"
                      :total="empagination.total"
                      :pager-count="5"
                      :hide-on-single-page="emonlyOne"
                    >
                    </el-pagination>
                  </div>
                </div>
              </el-col>
              <!-- 右侧 -->
              <el-col :span="12" class="right" v-loading="emloading">
                <div class="chat-details">聊天记录详情</div>
                <div class="details-right">
                  <div class="details-body" style="overflow:auto">
                    <div class="count">
                      <el-checkbox
                        :indeterminate="isIndeterminate"
                        v-model="checkAll"
                        @change="handleCheckAllChange2"
                        class="checkallbox"
                        >全选</el-checkbox
                      >
                      <div class="first-title">评审总量：{{ review }}条</div>
                      <div class="count-people">总条数：{{ total }}条</div>
                    </div>
                    <ul class="infinite-list chat-body">
                      <li
                        v-for="item in emthirdFloor"
                        :key="item.id"
                        class="infinite-list-item3"
                        :style="item.qualityFlag == 0 ? '' : 'color:#cec4d5'"
                      >
                        <div class="checkBox">
                          <el-checkbox
                            class="third-checkbox"
                            :label="item.fromId"
                            v-model="item.testBox"
                            @change="(value) => changeBox(value, item)"
                            :disabled="item.qualityFlag == 1"
                          >
                            {{ item.fromId }}
                          </el-checkbox>
                          <div class="third-time">{{ item.msgTime }}</div>
                          <div
                            v-show="item.qualityFlag - 0"
                            class="qualityFlag"
                          >
                            已评审
                          </div>
                        </div>
                        <!-- 判断消息类型 -->
                        <!-- <div class="third-msg"> -->
                        <div
                          :class="
                            item.fromId == item.serviceNick
                              ? 'third-msg2'
                              : 'third-msg'
                          "
                        >
                          <span
                            class="third-msg-content"
                            v-if="item.msgType === 0"
                            >{{ item.msgContent }}
                          </span>
                          <a
                            :href="item.msgContent"
                            target="_blank"
                            v-else-if="item.msgType === 1"
                            >点击跳转
                          </a>
                          <span
                            class="third-msg-content"
                            v-else-if="item.msgType === 2"
                            >【{{
                              item.msgContent
                            }}】--苹果手机表情+文字（不兼容）
                          </span>
                          <span
                            class="third-msg-content"
                            v-else-if="item.msgType === 3"
                            >【表情图暂不支持查看】
                          </span>
                          <a
                            :href="item.msgContent"
                            target="_blank"
                            v-else-if="item.msgType === 5"
                            >淘宝链接--点击查看
                          </a>
                          <a
                            target="_blank"
                            :href="item.msgContent"
                            v-else-if="item.msgType === 6"
                          >
                            【视频资源——点击查看】
                          </a>
                          <el-image
                            v-else-if="item.msgType === 7"
                            style="width: 80px; height: 80px"
                            :src="item.msgContent"
                            :preview-src-list="[item.msgContent]"
                          >
                          </el-image>
                          <!-- <img
                        :src="item.msgContent"
                        v-if="item.msgType === 7"
                        @click="showImg(item.msgContent)"
                      /> -->
                          <span
                            class="third-msg-content"
                            v-else-if="item.msgType === 9"
                            >【{{ item.msgContent }}】</span
                          >
                          <span
                            class="third-msg-content"
                            v-else-if="item.msgType === 10"
                            >[{{ item.msgContent }}]
                          </span>
                          <span
                            class="third-msg-content"
                            v-else
                            >{{ item.msgContent }}
                          </span>
                        </div>
                        <!-- <span>{{ item.msgContent }}</span> -->
                      </li>
                    </ul>
                  </div>
                  <div class="right-footer">
                    <el-button size="small" @click="openNewQa()"
                      >新建问答
                    </el-button>
                    <el-button size="small" type="primary" @click="openDialog()"
                      >评审标注
                    </el-button>
                  </div>
                </div>
                <!-- 头部 -->
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="聊天关系" name="2">
    <!-- 列表框 -->
    <el-row class="transfer">
      <el-col :span="24">
        <el-row class="transfer-left">
          <!-- 左侧 -->
          <el-col :span="12" class="left">
            <!-- 左侧 -->
            <div class="chat">
              <div>聊天关系</div>
              <div>
                <el-button-group v-show="secondFloor.length">
                  <el-button size="small" :type="btn1" @click="sortTime()"
                    >时间</el-button
                  >
                  <el-button size="small" :type="btn2" @click="sortNum()"
                    >数量</el-button
                  >
                </el-button-group>
              </div>
            </div>
            <div class="title-body">
              <el-row>
                <!-- 第一层聊天 -->
                <el-col :span="12" class="body-left">
                  <div class="count">
                    <div class="first-title">
                      当前聊天关系数: {{ relation }}人
                    </div>
                    <div class="count-people">
                      当前顾客数：{{ collection }}人
                    </div>
                  </div>
                  <ul class="infinite-list">
                    <li
                      v-for="(item, i) in firstFloor"
                      :key="i"
                      :class="{ active: i == isActive }" @click="() => { isActive = i; changeValue(i, item.adminId, item.subaccountId) }" class="infinite-list-item"
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        placement="bottom"
                        :enterable="false"
                        :open-delay="500"
                      >
                        <div slot="content">
                          {{ item.adminName + " " + item.subaccountName }}<br />
                          {{
                            item.stateType === "0"
                              ? " "
                              : item.stateType === "1"
                              ? "状态: ( 错登 )"
                              : item.stateType === "2"
                              ? "状态: ( 未分配 )"
                              : "状态: ( 未打卡 )"
                          }}
                        </div>
                        <div class="first-title">
                          {{
                            "【" +
                              item.adminName +
                              "】" +
                              " " +
                              item.subaccountName +
                              " "
                          }}
                        </div>
                      </el-tooltip>
                      <div class="people">
                        {{ item.chatNum ? `${item.chatNum}` : "0" }}人
                      </div>
                    </li>
                  </ul>
                </el-col>
                <!-- 第二层聊天 -->
                <el-col :span="12" class="body-right">
                  <div class="count">
                    <div class="first-title">当前咨询量：{{ consult }}条</div>
                    <div class="count-people">已读总量：{{ read }}条</div>
                  </div>
                  <ul class="infinite-list">
                    <li v-for="(item, i) in secondFloor" :key="i" :class="{ active:i == isActive2 }" class="infinite-list-item" @click="() => {isActive2 = i;changeFlag(i, item.customerNick)}">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.customerNick"
                        placement="bottom"
                        :enterable="false"
                        :open-delay="500"
                      >
                        <div class="readCus">
                          {{ item.customerNick }}
                        </div>
                      </el-tooltip>
                      <div
                        :class="{ read: true, 'check-read': i == isActive2 }"
                        v-if="item.readFlag"
                      >
                        已读
                      </div>
                      <div
                        :class="{
                          'no-read': true,
                          'check-read': i == isActive2
                        }"
                        v-else
                      >
                        未读
                      </div>
                      <div class="people">
                        {{ item.chatNum > 99 ? "99+" : `${item.chatNum}` }}条
                      </div>
                    </li>
                  </ul>
                  <div class="pagenum">
                    <el-pagination
                    small
                    v-show="secondFloor.length"
                    @size-change="(e) => pageFun(e, 'pageSize')"
                    @current-change="(e) => pageFun(e)"
                    style="text-align: center"
                    :page-size="params.pageSize"
                    :current-page="params.page"
                    layout="prev, pager, next, jumper"
                    :total="pagination.total"
                    :pager-count="5"
                    :hide-on-single-page="onlyOne"
                  >
                  </el-pagination>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <!-- 右侧 第三层 -->
          <el-col :span="12" class="right">
            <div class="chat-details">聊天记录详情</div>
            <div class="details-right">
              <div class="details-body" style="overflow:auto">
                <div class="count">
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                    class="checkallbox"
                    >全选</el-checkbox
                  >
                  <div class="first-title">评审总量：{{ review }}条</div>
                  <div class="count-people">总条数：{{ total }}条</div>
                </div>
                <ul class="infinite-list chat-body">
                  <li
                    v-for="item in thirdFloor"
                    :key="item.id"
                    class="infinite-list-item3"
                    :style="item.qualityFlag == 0 ? '' : 'color:#cec4d5'"
                  >
                    <div class="checkBox">
                      <el-checkbox
                        class="third-checkbox"
                        :label="item.fromId"
                        v-model="item.testBox"
                        @change="(value) => changeBox(value, item)"
                        :disabled="item.qualityFlag == 1"
                      >
                        {{ item.fromId }}
                      </el-checkbox>
                      <div class="third-time">{{ item.msgTime }}</div>
                      <div v-show="item.qualityFlag - 0" class="qualityFlag">
                        已评审
                      </div>
                    </div>
                    <!-- 判断消息类型 -->
                    <!-- <div class="third-msg"> -->
                    <div
                      :class="
                        item.fromId == item.serviceNick
                          ? 'third-msg2'
                          : 'third-msg'
                      "
                    >
                      <span class="third-msg-content" v-if="item.msgType === 0"
                        >{{ item.msgContent }}
                      </span>
                      <a
                        :href="item.msgContent"
                        target="_blank"
                        v-else-if="item.msgType === 1"
                        >点击跳转
                      </a>
                      <span
                        class="third-msg-content"
                        v-else-if="item.msgType === 2"
                        >【{{ item.msgContent }}】--苹果手机表情+文字（不兼容）
                      </span>
                      <span
                        class="third-msg-content"
                        v-else-if="item.msgType === 3"
                        >【表情图暂不支持查看】
                      </span>
                      <a
                        :href="item.msgContent"
                        target="_blank"
                        v-else-if="item.msgType === 5"
                        >淘宝链接--点击查看
                      </a>
                      <a
                        target="_blank"
                        :href="item.msgContent"
                        v-else-if="item.msgType === 6"
                      >
                        【视频资源——点击查看】
                      </a>
                      <el-image
                        v-else-if="item.msgType === 7"
                        style="width: 80px; height: 80px"
                        :src="item.msgContent"
                        :preview-src-list="[item.msgContent]"
                      >
                      </el-image>
                      <!-- <img
                        :src="item.msgContent"
                        v-if="item.msgType === 7"
                        @click="showImg(item.msgContent)"
                      /> -->
                      <span
                        class="third-msg-content"
                        v-else-if="item.msgType === 9"
                        >【{{ item.msgContent }}】</span
                      >
                      <span
                        class="third-msg-content"
                        v-else-if="item.msgType === 10"
                        >[{{ item.msgContent }}]
                      </span>
                      <span
                        class="third-msg-content"
                        v-else
                        >{{ item.msgContent }}
                      </span>
                    </div>
                    <!-- <span>{{ item.msgContent }}</span> -->
                  </li>
                </ul>
              </div>
              <div class="right-footer">
                <el-button size="small" @click="openNewQa()"
                  >新建问答
                </el-button>
                <el-button size="small" type="primary" @click="openDialog()"
                  >评审标注
                </el-button>
              </div>
            </div>
            <!-- 头部 -->
          </el-col>
        </el-row>
      </el-col>
      <!-- <el-col :span="6"></el-col> -->

      <!-- 右侧 -->
      <!-- <el-col :span="6">
          <div class="grid-content bg-purple-light">
            <div class="demo-basic--circle">
              <el-avatar :size="50" :src="circleUrl"></el-avatar>
              <div class="info">
                <span>王小虎</span>
                <span>账号: 12345</span>
              </div>
            </div>
          </div>
          <div>
            <div class="detail-right">所属部门: 客服部</div>
            <div class="detail-right">所属小组: --</div>
            <el-divider></el-divider>
            <div class="detail-right">今日应检阅量:</div>
            <div class="detail-right">已评审数量:</div>
            <div class="detail-right">新建问答量:</div>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="12">
                <div class="detail-right">客服话术评分:</div>
                <div class="detail-right">咨询人数:</div>
                <div class="detail-right">首响时间 (s):</div>
                <div class="detail-right">问答比 (%):</div>
                <div class="detail-right">转出/转入人数:</div>
                <div class="detail-right">最大同时接待:</div>
              </el-col>
              <el-col :span="12">
                <div class="detail-right">知识库转化量:</div>
                <div class="detail-right">接待人数:</div>
                <div class="detail-right">平均时间 (s):</div>
                <div class="detail-right">回复率 (%):</div>
                <div class="detail-right">未回复人数:</div>
                <div class="detail-right">平均接待时长 (s):</div>
              </el-col>
            </el-row>
          </div>
        </el-col> -->
    </el-row>
      </el-tab-pane>
    </el-tabs>

    <!-- 评审标注弹出层 -->
    <el-dialog
      v-dialogDrag
      title="质检评审"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :append-to-body="true"
    >
      <!-- 主体区域 -->
      <el-form ref="formRef" :model="form" label-width="120px">
        <el-form-item label="问题分类" prop="classificationQualityIds">
          <el-cascader
            class="common-screen-input_85"
            ref="myCascader"
            v-model="form.classificationQualityIds"
            :options="qulityoptions"
            :props="cateProps"
            clearable
            :show-all-levels="false"
            @change="(val) => handleChange(val, 'quality')"
          ></el-cascader>
          <!-- <el-input
            style="width: 217px"
            v-model="form.classificationBrandIds"
            placeholder="请选择问题分类"
            @click.native="sendChild($event)"
          ></el-input> -->
          <!-- <el-button type="text" class="dialogBtn" @click="goClass()"
            >分类设置
          </el-button> -->

          <!-- inputTree 子组件 -->
          <!-- <input-tree :treeData="treeData1" @childVal="childVal" ref="tree"></input-tree> -->
        </el-form-item>
        <el-form-item label="品牌质检分类" prop="classificationBrandIds">
          <el-cascader
            class="common-screen-input_85"
            v-model="form.classificationBrandIds"
            :options="brandoptions"
            :props="cateProps"
            clearable
            :show-all-levels="false"
            @focus="getBrandIds()"
            @change="(val) => handleChange(val, 'brand')"
          ></el-cascader>
          <!-- <el-input
            style="width: 217px"
            v-model="form.classificationQualityIds"
          ></el-input> -->
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            class="common-screen-input_85 cascader"
            type="textarea"
            v-model="form.remark"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="业务类型" prop="businessType">
          <el-select
            class="common-screen-input_85"
            v-model="form.businessType"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in typeoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="减分详情" prop="pointsDeduction">
          <el-input
            class="common-screen-input_85 cascader"
            type="textarea"
            v-model="form.pointsDeduction"
          ></el-input>
        </el-form-item>
        <el-form-item label="扣款金额" prop="amount">
          <el-input
            class="common-screen-input_85 cascader"
            v-model="form.amount"
          ></el-input>
        </el-form-item>
        <el-form-item label="评审评分" prop="score">
          <el-input
            class="common-screen-input_85 cascader"
            v-model="form.score"
          ></el-input>
          <el-button type="text" class="dialogBtn" @click="reset()"
            >恢复
          </el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-checkbox label="发送通知" name="122331"></el-checkbox>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button :loading="dialogLoad" type="primary" @click="sendForm()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 新建问答弹出层 -->
    <el-dialog
      v-dialogDrag
      title="新建问答"
      :visible.sync="newQaDialogVisible"
      :append-to-body="true"
      :before-close="newQaHandleClose"
    >
      <!-- 主体区域 -->
      <el-form ref="form" :model="newQaForm" class="newQaForm">
        <el-form-item label="问题名称" prop="questionName">
          <el-input
            v-model="newQaForm.questionName"
            placeholder="请填写问题名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="问题分类" prop="typeId">
          <el-select
            @change="selectWorkName"
            v-model="newQaForm.typeId"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.typeName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="newQaValue">
          <el-transfer
            class="move"
            v-model="newQaValue"
            :data="arr"
            :button-texts="['加入左边', '加入右边']"
            :titles="['关键词', '答案']"
            @change="transferChange"
            @left-check-change="leftChange"
            @right-check-change="rightChange"
          >
            <!-- 决定怎么显示方式 -->
            <span slot-scope="{ option }">{{ option.label }}</span>
          </el-transfer>
        </el-form-item>
        <!-- 暂时隐藏 -->
        <!-- <el-form-item>
          <div>
            <span>关键词不包含</span>
            <el-input
              v-model="newQaForm.name"
              placeholder="请填写问题名称"
            ></el-input>
          </div>
          <div>
            <span>答案仅可设置一个图片</span>
          </div>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeNewQa">取 消</el-button>
        <el-button type="primary" @click="sendQaForm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
import {
  relationList,
  qualityReview,
  qualityByAccount,
  qualityChatHis,
  qualityList,
  cusList,
  addKnowledge,
  badEmotionList,
  readEmotion
} from "../../service/service";
import { questionList } from "../../service/auto";
import { getShopDetail } from "../../service/common.js";
import { DateTransform } from "../../utils";
import CommonDate from "../../components/common/commonDate.vue";
// 导入子组件
// import inputTree from "./children/inputTree";

export default {
  components: {
    Breadcrumb,
    CommonDate
    // inputTree
  },
  data() {
    return {
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "客服", isLink: false },
        { title: "客服质检", isLink: true, url: "/service/service-test" },
        { title: "详情质检", isLink: false }
      ],
      shopDetail: {}, // 店铺详情
      loading: false,
      params: {
        shopId: this.$route.query.shopid, //店铺id
        adID: "", //客服id 2&3层
        adminId: "", //查询客服id  1层这个是下拉框选中ID
        sortType: "time", //2层
        // customers: "" //下拉
        checkDate: DateTransform(new Date().getTime() - 3600 * 1000 * 24),
        checkEndDate: DateTransform(new Date().getTime() - 3600 * 1000 * 24),
        page: 1,//2层分页
        pageSize: 3000//2层分页
      },
      qualityParams: {
        page: 1,
        pageSize: 10,
        type: ""
      },
      // 日期选择
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      isActive: false, //li样式
      isActive2: false, //li样式
      custList: [], //下拉列表
      firstFloor: [], //第一层
      secondFloor: [], //第二层聊天
      thirdFloor: [], //第san层聊天
      checked: false, //第san层聊天复选
      // treeData1: [], //传给子组件数据
      qulityoptions: [], //问题 级联的数据源
      brandoptions: [], //品牌 级联的数据源
      cateProps: {
        value: "id",
        label: "name",
        children: "childrenList",
        multiple: true
      },
      // 售前 、中 、 后
      typeoptions: [
        {
          value: "售前",
          label: "售前"
        },
        {
          value: "售中",
          label: "售中"
        },
        {
          value: "售后",
          label: "售后"
        }
      ],
      //////////////////////
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      dialogVisible: false, //评审标注弹出层
      // 质检评审数据
      form: {
        classificationQualityIds: [],
        classificationBrandIds: [],
        remark: "",
        businessType: "",
        pointsDeduction: "",
        score: 100,
        amount: 0,
        //////
        chatIds: [],
        shopId: this.$route.query.shopid,
        serviceInspectedId: "",
        subaccountId: "",
        customerAccount: "",
        pointDeduction: ""
      },
      dialogLoad: false, //控制标注提交多次点击
      btn1: "primary", //时间&&数量按钮变色
      btn2: "", //时间&&数量按钮变色
      imgDialogVisible: false, //图片显示
      imgSrc: "", //图片地址
      relation: 0, //第一层当前关联客服数
      collection: 0, //第一层当前客服数
      consult: 0, //第2层当前咨询量
      read: 0, //第2层已读总量
      review: 0, //第3层评审总量
      total: 0, //总条数

      //////////////////// 新建问答 ///////////////
      newQaDialogVisible: false,
      newQaForm: {
        questionName: ""
      }, //主体区域

      newQaValue: [], // Transfer 穿梭框
      classList: [], //所属分类下拉数据
      arr: [], // Transfer 穿梭框 de data
      arr2: [], // 关键词
      word_content: [], //文字答案
      img_content: "", //图片答案
      isIndeterminate: false,
      // isIndeterminate2: false,//情绪
      checkAll: false,
      // checkAll2: false,//情绪
      childCheckNum: 0,
      thirdQuaFlagNum: 0,
      emradioVal: 1,
      radioVal: 2,
      pagination:{
        total: 0,
        pageSizes: 10,
        // pageSizes: [10, 20, 50, 100],
      },
      empagination: {
        total: 0,
        pageSizes: 10
        // pageSizes: [10, 20, 50, 100],
      },
      onlyOne: true, //只有一页时隐藏页码
      emonlyOne: true, //只有一页时隐藏页码
      emOptions: [
        { prob: "90%", val: "0.9" },
        { prob: "80%", val: "0.8" },
        { prob: "70%", val: "0.7" },
        { prob: "60%", val: "0.6" }
      ],
      activeName: "1",
      emloading: false,
      emParams: {
        //情绪参数
        shopId: this.$route.query.shopid, //店铺id
        prob: "0.9",
        checkDate: DateTransform(new Date().getTime()),
        checkEndDate: DateTransform(new Date().getTime()),
        pageNum: 1,
        pageSize: 3000 //3000
      },
      emList: [], //情绪列表\
      emTotal: 0,
      emthirdFloor: []
    };
  },
  watch: {
    isActive(newVal) {
      this.isActive = newVal;
      this.isActive2 = false;
      this.thirdFloor = [];
      this.read = 0;
      this.total = 0;
    },
    isActive2(newVal) {
      this.isActive2 = newVal;
      this.review = 0;
    }
  },
  methods: {
    handleClick(tab) {
      if (tab.name == "1") {
        // this.getbadEmotionList();
        this.clear();
      } else {
        // this.getChatList();
        this.clear();
      }
    },
    // 2层分页
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.changeValue(this.isActive,this.params.adID,this.params.subaccountId,'savePage');
      this.isActive2 = 0
    },
     // 2层分页
    pageFun2(e, type) {
      // 分页
      if (type == "pageSize") {
        this.emParams.pageSize = e;
      } else {
        this.emParams.pageNum = e;
      }
      this.getbadEmotionList();
    },
    commonGetDate(startTime, endTime, e) {
      this.params.checkDate = startTime;
      this.params.checkEndDate = endTime;
      this.radioVal = e;
      this.getChatList();
      this.secondFloor = [];
      this.thirdFloor = [];
      this.isActive2 = 0
    },
    commonGetDate2(startTime, endTime, e) {
      this.emParams.checkDate = startTime;
      this.emParams.checkEndDate = endTime;
      this.emradioVal = e;
      this.getbadEmotionList();
      this.emthirdFloor = [];
      this.isActive2 = 0
    },
    commonFun() {
      this.params.pageSize = 3000;
      this.params.page = 1;
      this.getChatList();
    },
    async getCustomeList() {
      const { data } = await cusList(this.params);
      console.log(data, "客服下拉列表");
      this.custList = data;
      // 获取companyId----- 新建问答use
      data.forEach((item) => {
        this.newQaForm.companyId = item.companyId;
      });
    },
    async getShopDetail() {
      // 获取店铺详情
      let resData = (await getShopDetail({ shopId: this.$route.query.shopid }))
        .data;
      this.shopDetail = resData[0];
    },
    addEdit(e) {
      // 新增修改弹窗
      this.centerDialogVisible = true;
      console.log(e);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    addDrawer(id) {
      //新增员工
      this.drawer = true;
      if (id) {
        this.tableRowId = id;
      }
    },
    // 获取第一层聊天
    async getChatList() {
      this.loading = true;
      this.collection = 0;
      this.consult = 0;
      this.read = 0;
      this.review = 0;
      this.total = 0;
      try {
        let params = { ...this.params };
        // params.checkDate = this.checkTime[0];
        // params.checkEndDate = this.checkTime[1];

        // if (params.customers) {
        //   params.adminId = params.customers;
        // }
        // if (params.adminId) {
        //   params.adID = params.adminId;
        // }
        delete params.customerNick;
        delete params.pageSize;
        delete params.page;
        const { data } = await relationList(params);
        console.log(data, "第一层聊天");
        this.firstFloor = data;
        this.relation = data.length;
        for (let item of data) {
          this.collection += parseFloat(item.chatNum);
        }
        this.$message.success("获取成功！");
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },

    select() {
      this.secondFloor = []; //清空第二层
      this.thirdFloor = []; //清空第三层
      this.isActive = false;
      this.isActive2 = false;
      this.collection = 0;
      this.getChatList();
    },
    clear() {
      this.emradioVal = 1;
      this.radioVal = 2
      // this.$refs.commonReset.resetFun(this.radioVal,this.params.checkDate,this.params.checkEndDate);
      this.$refs.commonReset.resetFun(
        this.activeName == "1" ? this.emradioVal : this.radioVal,
        this.activeName == "1" ? this.emParams.checkDate:this.params.checkDate,
        this.activeName == "1" ? this.emParams.checkEndDate:this.params.checkEndDate
      );
      this.params = {
        shopId: this.$route.query.shopid, //店铺id
        adID: "",
        adminId: "", //查询客服id
        checkDate: DateTransform(new Date().getTime() - 3600 * 1000 * 24),
        checkEndDate: DateTransform(new Date().getTime() - 3600 * 1000 * 24),
        sortType: "time",
        page: 1,//2层分页
        pageSize: 3000//2层分页
      };
      this.emParams = {
        //情绪参数
        shopId: this.$route.query.shopid, //店铺id
        prob: "0.9",
        checkDate: DateTransform(new Date().getTime()),
        checkEndDate: DateTransform(new Date().getTime()),
        pageNum: 1,
        pageSize: 3000 //3000
      };
      this.isActive = false;
      this.isActive2 = false;
      this.collection = 0;
      this.activeName == "1" ? this.getbadEmotionList() : this.getChatList(); // 获取第一层聊天
      this.secondFloor = [];
      this.thirdFloor = [];
      this.emthirdFloor = []
    },
    openDialog() {
      if (!this.form.chatIds.length) {
        this.$message.error("请先选择聊天记录");
        this.dialogVisible = false;
      } else {
        this.dialogVisible = true;
      }

      this.sendChild();
    },
    goClass() {
      //分类设置
      this.$router.push("/service/test-sort");
    },
    //样式 && 【获取第二层聊天】
    async changeValue(index, adId, id,save) {
      this.loading = true;
      this.isActive = index; //css
      this.params.adID = adId;
      this.params.subaccountId = id;
      this.consult = 0;
      this.read = 0;
      
      if(save !== 'savePage'){
        this.params.page = 1;
        this.params.pageSize = 3000
      }
      let params = { ...this.params };
      
      if (params.adID) {
        params.adminId = params.adID;
      }
      const res = await qualityByAccount(params);
      console.log(res.data, "第二层聊天");
      if (res.code != 200) {
        return this.$message.error("获取失败！");
      }
      if (res.data && res.data.list && res.data.list.length) {
        this.secondFloor = res.data.list;
        this.consult = res.data.total; //当前咨询量
        this.pagination.total = res.data.total;
        this.pagination.pageSizes = res.data.pageSize
        this.onlyOne = (this.pagination.total / this.pagination.pageSizes) < 1
      } else {
        this.secondFloor = [];
      }

      for (let item of res.data.list) {
        if (item.readFlag == "1") {
          this.read++;
        }
      }
      this.loading = false;
      // if (!this.secondFloor.length) return (this.thirdFloor = []); //点击第一层清空第三层聊天
    },
    sortTime() {
      this.params.sortType = "time";
      this.btn1 = "primary";
      this.btn2 = "";
      this.read = 0;
      this.changeValue(
        this.isActive,
        this.params.adID,
        this.params.subaccountId,
        'savePage'
      );
      // this.isActive = false;
      this.isActive2 = false;
    },
    sortNum() {
      this.params.sortType = "num";
      this.btn2 = "primary";
      this.btn1 = "";
      this.read = 0;
      this.changeValue(
        this.isActive,
        this.params.adID,
        this.params.subaccountId,
        'savePage'
      );
      // this.isActive = false;
      this.isActive2 = false;
    },
    // 改变readFlag && 【获取第三层聊天】
    async changeFlag(index, nick) {
      this.loading = true;
      this.review = 0;
      this.form.chatIds = []; //////清空第三层一选择的复选框
      this.thirdQuaFlagNum = 0; //全选用
      this.isIndeterminate = false; //全选用
      this.checkAll = false; //全选用
      this.childCheckNum = 0; //全选用
      this.isActive2 = index; //css
      this.params.customerNick = nick;
      let params = { ...this.params };
      // params.checkDate = this.checkTime[0];
      // params.checkEndDate = this.checkTime[1];
      if (params.adID) {
        params.adminId = params.adID;
      }
      const { data } = await qualityChatHis(params);
      console.log(data, "第三层聊天");
      data.map((item) => {
        item.testBox = false;
        if (item.qualityFlag == 0||item.qualityFlag ==2) {
          this.thirdQuaFlagNum++;
        }
      });
      this.thirdFloor = data;
      this.arr = [];
      // this.$forceUpdate();
      this.read = 0;
      this.changeValue(
        this.isActive,
        this.params.adID,
        this.params.subaccountId,
        'savePage'
      );
      this.total = data.length;
      for (let item of data) {
        if (item.qualityFlag == "1") {
          this.review++;
        }
      }
      this.loading = false;
    },
    // 第三层 复选框改变
    changeBox(val, item) {
      // console.log(val, "子复选框改变"); //true/false
      if (val) {
        this.childCheckNum++;

        this.form.serviceInspectedId = item.serviceId;
        this.form.subaccountId = item.subId;
        this.form.chatIds.push(item.id);

        // this.arr2.push(item.msgContent);
        this.arr2.push({
          // key: item.msgContent,
          key: item.id,
          label: item.msgContent
        });
        // this.arr22
        this.arr.push({
          // key: item.msgContent,
          key: item.id,
          label: item.msgContent
        });

        this.newQaForm.keywords = this.arr2;
      } else {
        this.childCheckNum--;

        this.form.chatIds = this.form.chatIds.filter((i) => {
          return i != item.id;
        });
        this.arr.pop(item); //取消勾选删除
        this.arr2.pop(item);
      }
      this.form.subaccountId = item.subId;
      this.form.customerAccount = item.customerNick;
      this.form.serviceInspectedId = item.serviceId;
      //////////////////////////
      this.checkAll = this.childCheckNum === this.thirdQuaFlagNum;
      this.isIndeterminate =
        this.childCheckNum > 0 && this.childCheckNum < this.thirdQuaFlagNum;
    },
    // 级联改变
    handleChange(val, type) {
      // console.log(this.qualityParams.type);
      // if (this.qualityParams.type === "quality") {
      if (type === "quality") {
        console.log(val, "val++++++++++++++++++");
        console.log(
          this.$refs.myCascader.getCheckedNodes(),
          "getCheckedNodes+++++++++++++++"
        );
        this.form.classificationQualityIds = val;
        //遍历选取的label值，拼接
        let points = "";
        let score = 0; //得分
        let name = "";
        let amount = 0; //扣款
        points = this.$refs.myCascader.getCheckedNodes();
        if (val && val.length) {
          val.map((item) => {
            if (item && item[2]) {
              points.map((itemPoints) => {
                if (itemPoints.value == item[2]) {
                  //是否第三层，相等进来
                  if (itemPoints.data.hierarchy == 3) {
                    name +=
                      "【" +
                      itemPoints.data.name +
                      "】" +
                      "-" +
                      itemPoints.data.pointDeduction +
                      "分" +
                      ",";
                    score += itemPoints.data.pointDeduction;
                    amount += Number(itemPoints.data.amountDeduction); //扣款
                  }
                }
              });
            }
          });
        }
        // let pointsarr = []; //显示名字
        // let score = 0; //得分
        // let name = "";
        // if (points) {
        //   for (let i of points) {
        //     pointsarr.push(i.label);
        //     score += i.data.pointDeduction;
        //     this.form.pointDeduction = i.data.pointDeduction;
        //     if (i.data.hierarchy == 3) {
        //       name +=
        //         "【" +
        //         i.data.name +
        //         "】" +
        //         "-" +
        //         i.data.pointDeduction +
        //         "分" +
        //         ",";
        //     }
        //   }
        // }
        this.form.pointsDeduction = name;
        this.form.score = 100 - score;
        this.form.amount = amount;
        this.form.pointDeduction = score;
        console.log(this.form.score, "scoreddd");
      } else if (type === "brand") {
        this.form.classificationBrandIds = val;
        console.log(
          this.form.classificationBrandIds,
          "form.classificationBrandIds"
        );
      } else {
        this.form.classificationBrandIds = [];
        this.form.classificationQualityIds = [];
        this.form.score = 100;
        this.form.amount = 0;
        this.form.pointsDeduction = "";
      }
    },
    // 改变方式 && 请求问题分类
    async sendChild() {
      this.qualityParams.type = "quality";
      const { data } = await qualityList(this.qualityParams);
      console.log(data, "**sendChild**");
      this.qulityoptions = data.list;
    },
    async getBrandIds() {
      this.qualityParams.type = "brand";
      const { data } = await qualityList(this.qualityParams);
      console.log(data, "**getBrandIds**");
      this.brandoptions = data.list;
    },
    // async sendChild(event) {
    //   this.qualityParams.type = "quality";
    // 子组件方法
    //   this.$refs.tree.deptogglePanel(event);
    //   const { data } = await qualityList(this.qualityParams);
    //   console.log(data, "**sendChild**");
    //   this.treeData1 = data.list;
    // },
    childVal(val) {
      this.form.classificationBrandIds = val;
    },
    reset() {
      // this.form.score = "";
      this.handleChange();
    },
    // 发送评审dialog
    async sendForm() {
      try {
        this.dialogLoad = true;
        let form = { ...this.form };
        if (form.classificationQualityIds) {
          let classificationQualityIds = [];
          form.classificationQualityIds.map((item) => {
            if (item[2]) {
              classificationQualityIds.push(item[2]);
            }
          });
          form.classificationQualityIds = classificationQualityIds;
        }
        if (form.classificationBrandIds) {
          let classificationBrandIds = [];
          form.classificationBrandIds.map((item) => {
            if (item[2]) {
              classificationBrandIds.push(item[2]);
            }
          });
          form.classificationBrandIds = classificationBrandIds;
        }
        console.log(form, "质检评审参数***************");
        const res = await qualityReview(form);
        if(res.code == 200){
          this.$message.success("评审成功");
        }
      } catch (error) {
        console.log(error);
      }
      this.dialogLoad = false;
      this.review = 0;
      if(this.activeName == "1"){
        this.emchangeFlag(this.isActive2, this.emParams.customerNick,this.emParams.subaccountId);
      }else{
        this.changeFlag(this.isActive2, this.params.customerNick);
      }
      
      this.form.chatIds = []; //清空 子id
      this.closeDialog();
    },
    closeDialog() {
      this.$refs.formRef.resetFields();
      this.dialogVisible = false;
    },
    // 图片弹出层关闭
    imgHandleClose() {
      this.imgDialogVisible = false;
    },
    // 单击事件
    showImg(val) {
      this.imgSrc = val;
      this.imgDialogVisible = true;
    },

    //////////////////// 新建问答 ///////////////
    // 新建问答按钮
    openNewQa() {
      this.newQaDialogVisible = true;
      this.getSortList(); //所属分类下拉
    },
    newQaHandleClose() {
      this.newQaDialogVisible = false;
    },
    // 新建问答弹出层 -----确定按钮
    sendQaForm() {
      this.newAddkl();
      this.newQaDialogVisible = false;
    },
    // 选中值发生变化时触发
    selectWorkName(id) {
      let selectedName = {};
      selectedName = this.classList.find((item) => {
        return item.id === id;
        //筛选出匹配数据，是对应数据的整个对象
      });
      this.newQaForm.typeName = selectedName.typeName;
    },
    // 所属分类下拉
    async getSortList() {
      try {
        const { data: res } = await questionList({}, this.params.shopId);
        this.classList = res;
      } catch (error) {
        console.log(error);
      }
    },
    // 右侧列表元素变化时触发
    transferChange(value, direction, movedKeys) {
      console.log(value, direction, movedKeys);
      // this.arr22 = this.arr2;
      if (direction == "right") {
        this.word_content = this.newQaValue;

        // const index = this.arr22.indexOf(movedKeys[0]);
        // this.arr22.splice(index, 1);
      }
      // else {
      //   this.arr2.push(movedKeys[0]);
      // this.word_content = this.newQaValue;
      // }
      console.log(this.word_content, "this.word_content");
      console.log(this.arr2, "this.arr2");
      console.log(this.newQaValue, "newQaValue");
    },
    // 左侧列表元素被用户选中 / 取消选中时触发
    leftChange(one, two) {
      let arr2 = this.arr2;

      console.log(
        one,
        two,
        arr2,
        "leftChange????-----one是选中的数组--two是最后操作的元素--arr2[]"
      );
      let res = arr2.findIndex((item) => item.key == two); //查找到对应元素下标
      let arr = this.arr;
      let res2 = arr.findIndex((item) => item.key == two); //查找到对应元素下标
      let keyval = two.join("");
      res === -1
        ? arr2.findIndex((item) => item.key == two) == -1
          ? arr2.push({ key: keyval, label: arr[res2].label })
          : arr2
        : arr2.splice(res, 1);

      console.log(arr2, "这个才是最终的arr2");
    },
    // 右侧列表元素被用户选中 / 取消选中时触发
    rightChange(item, last) {
      let word = this.word_content;
      console.log(
        item,
        last,
        word,
        "rightChange*****-----item是选中的数组--last是最后操作的元素--word_content[]"
      );
      let arr2 = this.arr2;
      let arr = this.arr;
      item.forEach((val) => {
        let res2 = arr.findIndex((item) => item.key == val); //查找到对应元素下标
        arr2.findIndex((item) => item.key == val) == -1
          ? arr2.push({ key: val, label: arr[res2].label })
          : "";
      });
      console.log(this.arr2, this.word_content, "关键词----答案");
    },
    closeNewQa() {
      this.$refs.form.resetFields();
      this.newQaDialogVisible = false;
    },
    async newAddkl() {
      let params = { ...this.newQaForm };

      params.keywords = this.arr2;
      params.shopId = JSON.parse(this.params.shopId);

      await addKnowledge(params);
      this.$message.success("操作成功");

      this.arr = []; //清空数据源

      this.closeNewQa();
      // 刷新第三层数据
      this.changeFlag(this.isActive2, this.params.customerNick);
    },
    handleCheckAllChange(val) {
      console.log(val, "全选change"); //// true &false
      // this.checkedCities = val ? cityOptions : [];
      this.arr = []
      this.arr2 = []
      delete this.newQaForm.keywords
      this.childCheckNum = 0;
      this.form.chatIds = []; //清空 子id
      if (val) {
        // 子框选中  // 拿子id
        this.thirdFloor.map((item) => {
          if (item.qualityFlag == 0||item.qualityFlag == 2) {
            //qualityFlag == 0未被质检
            item.testBox = true;
            this.form.chatIds.push(item.id);
            this.form.serviceInspectedId = item.serviceId
            this.form.subaccountId = item.subId
            this.form.customerAccount = item.customerNick
            this.arr2.push({key: item.id,label: item.msgContent})//不知道影不影响
            this.arr.push({key: item.id,label: item.msgContent})//不知道影不影响

            this.childCheckNum++;
          }
        });
        this.newQaForm.keywords = this.arr2//不知道影不影响
        this.checkAll = this.childCheckNum === this.thirdQuaFlagNum;
        this.isIndeterminate =
          this.childCheckNum > 0 && this.childCheckNum < this.thirdQuaFlagNum;
        // this.isIndeterminate = true;
      } else {
        this.thirdFloor.map((item) => {
          item.testBox = false;
        });
        this.childCheckNum = 0;
        this.form.chatIds = [];
        this.isIndeterminate = false;
      }
    },
    handleCheckAllChange2(val) {
      console.log(val, "全选change"); //// true &false
      // this.checkedCities = val ? cityOptions : [];
      this.childCheckNum = 0;
      this.form.chatIds = []; //清空 子id
      if (val) {
        // 子框选中  // 拿子id
        this.emthirdFloor.map((item) => {
          if (item.qualityFlag == 0||item.qualityFlag == 2) {
            //qualityFlag == 0未被质检
            item.testBox = true;
            this.form.chatIds.push(item.id);
            this.childCheckNum++;
          }
        });
        this.checkAll = this.childCheckNum === this.thirdQuaFlagNum;
        this.isIndeterminate =
          this.childCheckNum > 0 && this.childCheckNum < this.thirdQuaFlagNum;
        // this.isIndeterminate = true;
      } else {
        this.emthirdFloor.map((item) => {
          item.testBox = false;
        });
        this.childCheckNum = 0;
        this.form.chatIds = [];
        this.isIndeterminate = false;
      }
    },
    async getbadEmotionList() {
      this.loading = true;
      let params = { ...this.emParams };
      const { data } = await badEmotionList(params);
      this.emList = data.records;
      this.empagination.total = data.total;
      this.empagination.pageSizes = data.size;
      this.emTotal = data.total;
      this.emonlyOne =
        this.empagination.total / this.empagination.pageSizes < 1;
      this.loading = false;
    },
    // 改变readFlag && 【获取第三层聊天】
    async emchangeFlag(index, nick, subId) {
      this.emloading = true;
      this.isIndeterminate = false;
      this.review = 0;
      this.form.chatIds = []; //////清空第三层一选择的复选框
      this.isActive2 = index; //css
      this.emParams.customerNick = nick;
      this.emParams.subaccountId = subId;
      let params = { ...this.emParams };
      delete params.shopId;
      delete params.prob;
      delete params.pageNum;
      delete params.pageSize;
      const { data } = await qualityChatHis(params);
      console.log(data, "第三层聊天");
      data.map((item) => {
        item.testBox = false;
        if (item.qualityFlag == 0 || item.qualityFlag ==2) {
          this.thirdQuaFlagNum++;
        }
      });
      this.emthirdFloor = data;
      // this.$forceUpdate();
      this.read = 0;
      this.total = data.length;
      for (let item of data) {
        if (item.qualityFlag == "1") {
          this.review++;
        }
      }
      this.emloading = false;
    },
    async emRead(id) {
      await readEmotion({ id: id });
      this.getbadEmotionList();
    }
  },
  created() {
    this.getbadEmotionList();
    // this.getChatList();
    this.getCustomeList(); //客服下拉列表
    this.getShopDetail();
  }
};
</script>
<style lang="less" scoped>
.detailTest-container {
  text-align: left;
  .hd {
    // height: 153px;
    .name {
      color: rgba(16, 16, 16, 100);
      font-size: 24px;
      padding: 18px 32px;
    }
    .gray {
      height: 1px;
      background-color: rgba(230, 230, 230, 100);
      margin-bottom: 24px;
    }
    .common-screen-container {
      padding-left: 16px;
    }
    .line {
      height: 16px;
      background-color: #ededed;
    }
  }
  /deep/ .el-tabs {
    margin-bottom: 24px;
    .el-tabs__header {
      margin-bottom: 24px;
    }
    .el-tabs__nav {
      padding-left: 8px;
    }
    // .el-tabs__content {
    //   padding: 0 32px;
    // }
  }
  .detailTest-detail {
    display: flex;
    padding: 0 0 37px 0px;
    align-items: center;
    color: #000000a6;
    .detail-img {
      width: 64px;
      height: 64px;
      margin-right: 20px;
      border-radius: 5px;
    }
    .detail-title {
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: bold;
    }
    .detail-item {
      margin-right: 16px;
    }
  }
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.dialog-input {
  width: 320px !important;
}
.selectdata {
  margin-right: 16px;
}
.demo-basic--circle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 15px;
  .info {
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
  }
}
.detail-right {
  height: 100%;
  line-height: 30px;
}
.el-divider--horizontal {
  margin: 8px 0;
}
.transfer {
  height: 560px;
}
.transfer-left {
  height: 100%;
}
.left,
.right {
  height: 100%;
}
.el-col-24 {
  height: 100%;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}

.chat,
.chat-details {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 10px;
}
.chat {
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #d9d9d9;
}
.title-body {
  height: 499px;
  border-right: 1px solid #d9d9d9;
  // padding: 0 10px;
  .body-left {
    overflow: overlay !important;
    border-right: 1px solid #d9d9d9;
    height: 499px;
  }
  .body-right {
    position: relative;
    padding-bottom: 4px;
    height: 499px;
    overflow: overlay !important;
    .pagenum {
      background-color: #fff;
      /deep/ .el-pagination__jump {
        margin-left: 6px;
      }
    }
  }
}
.right-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px !important;
  border-top: 1px solid #d9d9d9;
  text-align: center;
}
.chat-body {
  padding: 10px 10px 0;
}
.details-right {
  height: 499px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .details-body {
    overflow: overlay !important;
    position: relative;
    height: 440px;
  }
}
.dialogBtn {
  margin-left: 10px;
}
.count {
  position: sticky;
  top: 0;
  z-index: 10;
  height: 41px;
  line-height: 41px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: flex-start;
  padding: 0 30px;
  .count-people {
    font-size: 12px !important;
    white-space: nowrap;
    margin-left: 30px;
  }
}
.first-title {
  outline: none;
  font-size: 12px !important;
  overflow: hidden;
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.infinite-list-item {
  display: flex;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;

  .people {
    color: rgb(204, 204, 204);
    width: 32px;
    font-size: 12px !important;
    white-space: nowrap;
  }
  .empeople {
    color: rgb(204, 204, 204);
    width: 150px;
    font-size: 12px !important;
    white-space: nowrap;
  }
  .read {
    font-size: 12px !important;
  }
  .no-read {
    font-size: 12px !important;
  }
  .em-no-read {
    width: 50px;
    height: 18px;
    line-height: 18px;
    border: 1px solid #ffffff;
    background-color: #ff9a57;
    border-radius: 3px;
    text-align: center;
    margin: 16px 41px 16px 0px;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    font-size: 12px !important;
  }
  .readCus {
    outline: none;
    font-size: 12px !important;
    overflow: hidden;
    display: inline-block;
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.infinite-list-item2 {
  display: flex;
  // justify-content: space-between;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;

  .people {
    color: rgb(204, 204, 204);
    width: 32px;
    font-size: 12px !important;
    white-space: nowrap;
  }
  .empeople {
    text-align: right;
    color: rgb(204, 204, 204);
    width: 180px;
    font-size: 12px !important;
    white-space: nowrap;
  }
  .read {
    font-size: 12px !important;
  }
  .no-read {
    font-size: 12px !important;
  }
  .em-no-read {
    width: 50px;
    height: 18px;
    line-height: 18px;
    border: 1px solid #ffffff;
    background-color: #ff9a57;
    border-radius: 3px;
    text-align: center;
    margin: 16px 41px 16px 0px;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    font-size: 12px !important;
  }
  .readCus {
    outline: none;
    font-size: 12px !important;
    overflow: hidden;
    display: inline-block;
    max-width: 170px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.readCus {
  width: 165px;
}
.read {
  width: 50px;
  height: 18px;
  line-height: 18px;
  background-color: #ececec;
  border-radius: 3px;
  text-align: center;
  margin: 16px auto;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.em-read {
  width: 50px;
  height: 18px;
  line-height: 18px;
  background-color: #ececec;
  border-radius: 3px;
  text-align: center;
  margin: 16px 41px 16px 0px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.check-read {
  width: 50px;
  height: 18px;
  line-height: 18px;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 3px;
  text-align: center;
  margin: 16px auto;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.em-check-read {
  width: 50px;
  height: 18px;
  line-height: 18px;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 3px;
  text-align: center;
  margin: 16px 41px 16px 0px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.no-read {
  width: 50px;
  height: 18px;
  line-height: 18px;
  background-color: #ff9a57;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  margin: 16px 41px 16px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.infinite-list-item3 {
  padding: 10px 20px 0;
  .checkBox {
    display: flex;
    justify-content: space-between;
    height: 21px;
    .third-time {
      font-size: 12px !important;
    }
    // .third-msg-content {
    //   overflow: hidden;
    //   display: inline-block;
    //   max-width: 545px;
    //   font-size: 12px !important;
    // }
    .qualityFlag {
      border-radius: 3px;
      width: 40px;
      height: 20px;
      line-height: 20px;
      color: #333;
      background-color: #f4dda0;
      text-align: center;
      /* margin: 0 auto; */
      font-size: 12px;
    }
  }
  .third-msg {
    // background-color: #fafafa;
    border-radius: 5px;
    padding: 0 3px;
  }
  .third-msg2 {
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 0 3px;
  }
  span {
    overflow: hidden;
    display: inline-block;
    // max-width: 545px;
    font-size: 12px !important;
  }
  img {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
  }
}
.active {
  background-color: #1890ff;
  color: #fff;
}
/deep/.el-checkbox__label {
  color: rgb(24, 144, 255) !important;
  font-size: 12px !important;
}
.imgdialog {
  display: block;
  width: 100%;
  height: auto;
}
a:hover {
  color: #1890ff;
  text-decoration: underline;
}
.iconPeople {
  width: 80px;
  height: 80px;
  color: #fff;
  background: #87d068;
  border-radius: 50%;
  font-size: 70px;
  line-height: 80px;
  text-align: center;
  margin-right: 16px;
}
/deep/.el-dialog {
  width: 30%;
}
/deep/ .el-transfer__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /deep/ .el-transfer__button {
    width: 92px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
}
.newQaForm {
  .el-input {
    width: 87%;
  }
  .el-select {
    width: 87%;
  }
  // /deep/.el-form-item__content {
  //   display: flex;
  //   justify-content: space-between;
  // }
}
.cascader {
  width: 217px;
}
.move {
  display: flex;
  // height: 210px;
}
/deep/ .el-image-viewer__close {
  background: #606266;
  border-radius: 50%;
  .el-icon-circle-close:before {
    font-size: 20px;
    color: #fff;
    content: "\e6db";
  }
}
.checkallbox {
  margin-right: 16px;
}
.third-checkbox {
  color: #1890ff;
}
.emg {
  width: 92px !important;
}
.icon {
  width: 24px;
  vertical-align: middle;
}
.itemlabel {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.em-nick {
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.emcontent {
  width: 200px;
  padding-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.itemEM {
  width: 96px;
  // overflow: hidden;
  // text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 8px;
}
// .el-cascader-node {
//   overflow: hidden !important;
//   text-overflow: ellipsis !important;
//   white-space: nowrap !important;
//   width: 260px !important;
// }
</style>
